// translation.service.ts
import { Injectable } from '@angular/core';
import { TranslateService, TranslateLoader } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { Observable, EMPTY, of, delay } from 'rxjs';
import { GeofencingService } from './geofencing.service';

@Injectable({
  providedIn: 'root',
})
export class TranslationService  {
  language: string;

  private translations: { [key: string]: any } = {};

  constructor(
    private translate: TranslateService,
    private geofencingService: GeofencingService
  ) {
    this.translate.setDefaultLang('EN');
    this.language = 'EN';
  }

  async setUserLanguage() {
    this.switchLanguage(this.language);    
  }

  async setTranslationLanguages() {    
    this.translate.setTranslation('EN', this.translations['EN']);
    this.translate.setTranslation('DE', this.translations['DE']);
    this.translate.setTranslation('FR', this.translations['FR']);
    this.translate.setTranslation('IT', this.translations['IT']);
    this.translate.setTranslation('PO', this.translations['PO']);    
  }

  async loadTranslations(){    
    this.geofencingService.getAllRemoteTexts().subscribe(async (res: any[string]) => {
      this.translations[this.language] = res[this.language]['GEOFENCING'];
      await this.setTranslationLanguages();            
    });    
  }

  switchLanguage(language: string) {
    this.translate.use(language);
  }

  setLanguage(language: string) {
    this.language = language;
  }

  getLanguage(): string {
    return this.language;
  }

  getTranslations(): any {
    return this.translations;
  }
}


export class TranslationLoaderService implements TranslateLoader {

  constructor(private http: HttpClient) {
  }

  getTranslation(lang: string): Observable<any> {
    return EMPTY; 
  }
}
