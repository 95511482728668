import { NgModule, } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { GeofencingComponent } from './components/geofencing/geofencing.component'
import { LoginFormComponent } from './components/login/login-form.component';
import { 
  AuthGuardService as AuthGuard 
} from './services/auth/auth-guard.service';

const routes: Routes = [
  { path: 'login', component: LoginFormComponent },  
  { path: 'geofencing', component: GeofencingComponent, canActivate: [AuthGuard] },  
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: '**', redirectTo: 'notfound', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],  // {enableTracing: true, useHash: true}
  exports: [RouterModule]
})
export class AppRoutingModule { }
