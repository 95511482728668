import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DataService {
  private data = new Subject<any>();
  data$ = this.data.asObservable();

  sendSelectedPerimeter(data: any, user_id: any) {
    this.data.next({ value: data, type: 'SEL_PERIM', mode: user_id });
  }

  triggerReloadPerimeters(data: any) {
    this.data.next({ value: data, type: 'RELO_PERIM' });
  }

  sendUserPosition(last_pos: any, user_full_name: string, selected: boolean) {
    if (last_pos != null)
      if (last_pos.value != '')
        this.data.next({
          last_pos: last_pos,
          user_full_name: user_full_name,
          selected: selected,
          type: 'USER_POS',
        });
  }
}
