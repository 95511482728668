import { Input, Component, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { GeofencingService, UserId } from '../../services/geofencing.service';
import { TranslationService } from 'src/app/services/translation.service';
import { AuthService } from '../../services/auth/auth.service';
import { Router, ActivatedRoute } from '@angular/router';

const RE_URL: string = 'http://localhost:35452/';

// here goes the table of credentials  ***************

let authTable = [
  {
    user: 'luca',
    pass: 'Piga.5001',
    appl: ['Geo', 'MRE'],
    pilot: 'localhost',
    api_url: 'http://localhost:5016',
  },
  {
    user: 'exys',
    pass: 'Riva.2664',
    appl: ['Geo', 'MRE'],
    pilot: 'pilot 500',
    api_url: 'https://app.eliot-care.ch/api/v1',
    // api_url: 'http://172.24.24.173:5016/api/v1',
  },
];

// ****************************************************

@Component({
  selector: 'app-login',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.css'],
})
export class LoginFormComponent {
  loading: boolean = false;
  submitted: boolean = false;

  btnName: string = '';

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private geofencingService: GeofencingService,
    private translationService: TranslationService,
    private authService: AuthService
  ) {}

  form: FormGroup = new FormGroup({
    username: new FormControl('', [Validators.required]),
    password: new FormControl('', [Validators.required]),
  });

  setBtnName(name: string) {
    this.btnName = name;
  }

  ngOnInit(): void {
    // Listen for messages from the parent
    window.addEventListener('message', async (event) => {
      // Check the origin of the message for security purposes
      console.log(event.origin);
      if (event.origin == 'http://localhost:35551') {
        await this.geofencingService.setToken(
          'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmcmVzaCI6ZmFsc2UsImlhdCI6MTczMTM5ODU2OCwianRpIjoiNmNmNDk1ZmEtY2RhNS00Y2I2LTlhN2YtMjE4NzlkMWU4NDI4IiwidHlwZSI6ImFjY2VzcyIsInN1YiI6eyJ1aWQiOjQsInVzZXJuYW1lIjoiZXVnZW5pb19jYXZhbGxvdHRpIiwicm9sZSI6WyJDQVJFUiJdfSwibmJmIjoxNzMxMzk4NTY4LCJleHAiOjE3MzE0ODQ5Njh9.__iYw8bPX5lm8zMYiZd2zfKToqlV_g_HPnGn61M3d2c'
        )      
        setTimeout(() => {         
            // Update the received message
          /* this.form.value.username = event.data.username;
          this.form.value.password = event.data.password; */
          this.translationService.setLanguage('IT');        
          this.geofencingService.setUser(null); //set null for Team mode, user_id for User mode
          this.authentic('Geo');
        }, 1400);
      } else if (event.origin == 'https://app.eliot-care.ch') {
        await this.geofencingService.setToken(event.data.token);        
        setTimeout(() => {            
          if (event.data.hasOwnProperty('language')) {
            if (event.data.language != null)
              this.translationService.setLanguage(event.data.language);
            else this.translationService.setLanguage('EN');
          } else {
            this.translationService.setLanguage('EN');
          }
          if (event.data.hasOwnProperty('user_id')) {
            this.geofencingService.setUser(event.data.user_id);
          } else {
            this.geofencingService.setUser(null);
          }
          this.authentic('Geo');               
        }, 1400);                         
      } else return;
    });
  }

  validateLogin(appl: string) {
    this.form.value.username = 'exys';
    this.form.value.password = 'Riva.2664';
    let outcome: any = {
      valid: false,
      pilot: '',
      api_url: '',
    };
    authTable.forEach((entry) => {
      if (entry.appl.includes(appl))
        if (entry.user === this.form.value.username)
          if (entry.pass === this.form.value.password)
            outcome = {
              valid: true,
              api_url: entry.api_url,
              pilot: entry.pilot,
            };
    });
    return outcome;
  }

  authentic(event: string) {
    let validation = this.validateLogin(event);
    if (validation.valid) {
      this.authService.login();
      let url: string = '';
      if (event === 'Geo') url = '/geofencing';
      else return false;
      this.router
        .navigate([url])
        .then(async (x) => {
          console.log('Logged.');
          console.log(validation.api_url);
          if (validation.api_url != null) {
            this.geofencingService.setApiUrl(
              validation.api_url,
              validation.pilot
            );
            await this.translationService.loadTranslations();
            await this.translationService.setUserLanguage();            
            return true;
          } else return false;
        })
        .catch((err) => {
          console.log(err);
          return false;
        });
    }
    this.error = 'unable to authenticate';
    return false;
  }

  submit(event: string) {
    this.submitted = true;
    if (this.form.invalid) {
      return;
    } else {
      this.authentic(event);
    }
  }

  @Input() error: string | null | undefined;

  @Output() submitEM = new EventEmitter();
}
