import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';

@Injectable()
export class AuthService { constructor(public jwtHelper: JwtHelperService) {}
  
  public authenticated = false;

  public isAuthenticated(): boolean {    
    // const token = localStorage.getItem('token');    // Check whether the token is expired and return
    
    const token = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE5ODU3NTk3Mzd9.HUpUHZQhgfrGge7vUBBbGkNlUYWbpk5RyAUtJih3ZJY';

    // true or false  
    //this.authenticated =!this.jwtHelper.isTokenExpired(token);
    return (this.authenticated && !this.jwtHelper.isTokenExpired(token));
  }

  login() {
    // authentication logic, set authenticated to true
    this.authenticated = true;    
  }

  logout() {
    // logout logic, set authenticated to false
    this.authenticated = false;
  }
}