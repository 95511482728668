import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

interface DialogData {
  perimeter: string;
}

@Component({
  selector: 'datadisplay-dialog',
  templateUrl: './datadisplay.component.html',
  styleUrls: ['./datadisplay.component.css'],
})
export class DatadisplayComponent {
  dataContent: string;

  constructor(
    public dialogRef: MatDialogRef<DatadisplayComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {
    this.dataContent = this.data.perimeter;
  }

  onCloseClick() {
    this.dialogRef.close();
  }
}
