import { Component, OnDestroy } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import {
  GeofencingService,
  Perimeter,
  User,
  UserPerimeter,
} from 'src/app/services/geofencing.service';
import { Subscription } from 'rxjs';
import { DataService } from 'src/app/services/data.service';

/**
 * @title Dialog with header, scrollable content and actions
 */

import { ConfirmationDialog } from '../helpers/confirmation-dialog';

@Component({
  selector: 'users-perimeter',
  templateUrl: 'users.component.html',
  styleUrls: ['./users.component.css'],
})
export class UsersComponent implements OnDestroy {
  usersData: User[];
  usersPerimeter: UserPerimeter[];
  lastGeoPositions: [];
  displayedColumns: any[] = ['user'];
  selectedRow: boolean;
  subscription: Subscription;
  selectedPerimeter: Perimeter = {
    id: 0,
    name: '',
    selected: false,
    center: {
      name: '',
      coordinates: undefined,
    },
    coordinates: undefined,
    msg_inside: '',
    msg_outside: '',
    scheduling: undefined,
    init_zoom: 0,
    start_timestamp: '',
    end_timestamp: '',
    user_ids: [],
    users_count: 0,
  };

  dialogRef2?: MatDialogRef<ConfirmationDialog>;

  constructor(
    private geofencingService: GeofencingService,
    private dataService: DataService,
    public dialog: MatDialog
  ) {
    this.usersData = [];
    this.usersPerimeter = [];
    this.lastGeoPositions = [];
    this.selectedRow = false;

    this.subscription = this.dataService.data$.subscribe((data: any) => {
      if (data != undefined) {
        if (data.type == 'SEL_PERIM') {
          this.selectedPerimeter = data.value;
          this.getAllUsersOfPerimeter();
          this.selectUsersOfPerimeter();
        }
      }
    });
  }

  ngAfterViewInit(): void {
    this.geofencingService.getLastGeoPositions(null).subscribe((res) => {
      /* JSON returned: 
        [
          {
            "user_id": "string",
            "device_id": "string",
            "value": [
              "string"
            ],
            "value_timestamp": "string"
          }
        ] */
      this.lastGeoPositions = res;
      this.getAllUsers();
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  /**
   * Returns all users
   */
  getAllUsers() {
    let ud: User[] = [];
    this.geofencingService.getAllUsers().subscribe((res) => {
      res.forEach((u: User) => {
        u.selected = false;
        let gp: any = this.lastGeoPositions.find((obj: any) => {
          return obj.user_id == u.id;
        });
        if (gp) {
          u.last_position = gp;
          if (gp.value_timestamp != null) {
            u.last_position.value_timestamp = new Date(
              gp.value_timestamp * 1000
            ).toUTCString();
          }
        } else {
          u.last_position = {
            user_id: u.id,
            device_id: 0,
            value: '',
            value_timestamp: '',
          };
        }
        ud.push(u);
      });
      this.usersData = ud;
    });
  }

  /**
   * Returns all users of the selected perimeter
   */
  getAllUsersOfPerimeter() {
    this.usersPerimeter = [];
    this.usersData.forEach((x: any) => {
      x.selected = false;
    });
    let j: number = 0;
    if (this.selectedPerimeter.user_ids != undefined) {
      this.selectedPerimeter.user_ids.forEach((user_id: number) => {
        this.usersPerimeter.push({
          id: j++,
          user_id: user_id,
          perimeter_id: this.selectedPerimeter.id,
          scheduling: {},
          start_timestamp: '2023-10-06 08:00:00+00',
          end_timestamp: '3000-12-31 23:55:5+00',
        });
      });
    }
  }

  selectUsersOfPerimeter() {
    for (let i = 0; i < this.usersData.length; i++) {
      for (let j = 0; j < this.usersPerimeter.length; j++) {
        if (this.usersData[i].id == this.usersPerimeter[j].user_id)
          this.usersData[i].selected = true;
      }
    }
  }

  selectAllUsers(event: any): void {
    this.usersData.forEach((x: any) => {
      x.selected = event.source.checked ? true : false;
    });
  }

  setUserPositionMarker(row: any) {
    this.dataService.sendUserPosition(
      row.last_position,
      row.full_name,
      row.selected
    );
  }

  reload() {
    this.getAllUsersOfPerimeter();
    this.selectUsersOfPerimeter();
  }

  save() {
    let us: number[] = [];
    this.usersData.forEach((x: any) => {
      if (x.selected) us.push(x.id);
    });
    this.geofencingService
      .updateUsersPerimeter(this.selectedPerimeter.id, us)
      .subscribe((d) => {
        this.dataService.triggerReloadPerimeters(true);
      });
  }
}
