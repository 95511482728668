<mat-card>
<!--     <mat-card-title>Login</mat-card-title>
    <mat-card-content>
        <form [formGroup]="form" (ngSubmit)="submit($event.submitter.id)">
        <p>
            <mat-form-field>
            <input type="text" matInput placeholder="Username" formControlName="username">
            </mat-form-field>
        </p>

        <p>
            <mat-form-field>
            <input type="password" matInput placeholder="Password" formControlName="password">
            </mat-form-field>
        </p>

        <p *ngIf="error" class="error">
            {{ error }}
        </p>

        <div class="row">
            <div class="col-12">
                <div class="button">
                    <button type="submit" id="Geo" mat-button>Login Geofencing</button>
                </div>
            </div>
            
        </div>

        </form>
    </mat-card-content> -->
</mat-card>