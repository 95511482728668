import { Injectable } from '@angular/core';
import Map from 'ol/Map';
import MousePosition from 'ol/control/MousePosition.js';
import Select from 'ol/interaction/Select.js';
import View from 'ol/View';
import { createStringXY } from 'ol/coordinate.js';
import { Draw, Modify, Snap } from 'ol/interaction.js';
import { OSM, Vector as VectorSource } from 'ol/source.js';
import { Tile as TileLayer, Vector as VectorLayer } from 'ol/layer.js';
import { Feature } from 'ol';
import { Point } from 'ol/geom';
import Polygon from 'ol/geom/Polygon.js';
import { fromLonLat, transform, useGeographic } from 'ol/proj.js';
import Zoom from 'ol/control/Zoom.js';
import {
  Fill,
  Stroke,
  Style,
  Text,
  Circle as CircleStyle,
  Icon,
} from 'ol/style.js';
import Overlay from 'ol/Overlay.js';
import { Subject } from 'rxjs';
import { Perimeter } from './geofencing.service';
import { Subscription } from 'rxjs';
import { DataService } from 'src/app/services/data.service';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root',
})
export class GeomapService {
  map: Map;
  target: string = '';
  tooltip?: HTMLElement;
  popup: Overlay = new Overlay({
    element: this.tooltip,
  });

  flagEdit: boolean = false;

  initialCenter = [8.90755, 46.01301];
  initialZoom = 3;

  editPerimeterMode: string = '';
  newPerimeterFeature: Feature | undefined;
  drawnPerimeterCoords: Array<Array<string>> = [];

  raster = new TileLayer({
    source: new OSM(),
  });

  source = new VectorSource();
  vector = new VectorLayer({
    source: this.source,
    style: this.definePerimeterSelectStyle(''),
  });

  select = new Select();

  modify = new Modify({ source: this.source });
  draw = new Draw({
    source: this.source,
    type: 'Polygon',
  });
  snap = new Snap({ source: this.source });

  points: Array<Array<number>> = [];

  mousePositionControl = new MousePosition({
    coordinateFormat: createStringXY(10),
    projection: 'EPSG:4326',
    className: 'custom-mouse-position',
    placeholder: '<pre></pre>',
    //target: document.getElementById('mouse-position')
  });

  subscription: Subscription;

  // perimeterSelected observable, to inform the component when a perimeter is selected
  private perimeterSelected: Subject<Perimeter> = new Subject<Perimeter>();
  public perimeterSelectedObs = this.perimeterSelected.asObservable();

  constructor(private dataService: DataService) {
    useGeographic(); //use geographical coordinates [lon,lat]

    this.flagEdit = false; // true when in edit mode

    this.map = new Map({
      controls: [new Zoom()],
      //controls: defaultControls().extend([this.mousePositionControl]),
      view: new View({
        projection: 'EPSG:3857', //Web Mercator
        center: this.initialCenter,
        zoom: this.initialZoom,
      }),
      layers: [this.raster, this.vector],
    });

    this.map.on('click', (evt) => {
      if (this.flagEdit) {
        // If in edit mode
        var lonlat = evt.coordinate;
        var lon = lonlat[0];
        var lat = lonlat[1];
        var x: Array<number> = [lon, lat];
        this.points.push(x);
        this.map.forEachFeatureAtPixel(evt.pixel, (f) => {
          this.map.removeOverlay(this.popup);
        });
      } else {
        // If in selecting mode
        let popover = this.popup.getElement();
        if (popover) {
          popover.hidden = true;
        }
        let ip: Perimeter = {
          id: 0,
          name: '',
          selected: false,
          center: {
            name: '',
            coordinates: [],
          },
          coordinates: [],
          msg_inside: '',
          msg_outside: '',
          scheduling: null,
          init_zoom: 11,
          start_timestamp: '',
          end_timestamp: '',
          user_ids: [],
          users_count: 0,
        };
        this.perimeterSelected.next(ip);
        this.map.forEachFeatureAtPixel(evt.pixel, (f) => {
          this.map.addOverlay(this.popup);
          let ext = f.getGeometry()?.getExtent();
          let coords: number[];
          if (ext)
            coords = [
              ext[2] + (ext[2] - ext[0]) * 0.1,
              ext[3] - (ext[3] - ext[1]) * 0.1,
            ];
          else coords = [evt.coordinate[0] + 0.001, evt.coordinate[1] - 0.001];
          this.popup.setPosition(coords);
          let popover = this.popup.getElement();
          if (popover) {
            popover.hidden = true;
          }
          if (popover) {
            let pr = f.getProperties();
            if (pr['name'] != undefined && pr['id'] != undefined) {
              let start_dt = pr['start_timestamp'];
              let end_dt = pr['end_timestamp'];
              if (new Date(start_dt) < new Date('1970-01-02 00:00'))
                start_dt = '---';
              else
                start_dt = (moment(start_dt)).format('DD.MM.YYYY')
              if (new Date(end_dt) > new Date('3000-12-30 23:59'))
                end_dt = '---';
              else
                end_dt = (moment(end_dt)).format('DD.MM.YYYY')
              popover.innerHTML =
                pr['name'] +
                '<br>start: ' + 
                start_dt +
                '<br>end: &nbsp;&nbsp;' +
                end_dt;
              popover.hidden = false;
              ip = {
                id: pr['id'],
                name: pr['name'],
                selected: true,
                center: pr['center'],
                coordinates: pr['coordinates'],
                msg_inside: pr['msg_inside'],
                msg_outside: pr['msg_outside'],
                scheduling: pr['scheduling'],
                init_zoom: pr['init_zoom'],
                start_timestamp: pr['start_timestamp'],
                end_timestamp: pr['end_timestamp'],
                user_ids: pr['user_ids'],
                users_count: pr['users_count'],
              };
              this.perimeterSelected.next(ip);
            }
          }
        });
      }
      if (this.flagEdit) {
        this.changeInteraction('<add_new>');
      } else this.changeInteraction('<selected>');
    });

    this.source.on('addfeature', (evt) => {
      this.disableMapInsert();
      if (evt.feature != undefined) {
        this.newPerimeterFeature = evt.feature;
        let geometry = evt.feature.getGeometry();
        this.storeCoordinates(geometry);
        this.changeInteraction('');
      }
      this.map.addLayer(this.vector);
    });

    this.modify.on('modifyend', (evt) => {
      console.log('modified -end');
      let geometry = evt.features.getArray()[0].getGeometry();
      this.storeCoordinates(geometry);
    });

    this.subscription = this.dataService.data$.subscribe((data: any) => {
      if (data != undefined) {
        if (data.type == 'USER_POS') {
          if (data.hasOwnProperty('user_full_name')) {
            if (data.selected) {
              this.addMarker(data.last_pos, data.user_full_name);
            } else {
              this.removeLayerFromMap(data.user_full_name);
            }
          }
        }
      }
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  /* Private methods ********************* */

  private checkPerimeterTimeValidity(
    start_timestamp: string,
    end_timestamp: string
  ) {
    const start_datetime = new Date(start_timestamp);
    const end_datetime = new Date(end_timestamp);
    let a: boolean = false;
    let now = new Date();
    if (start_timestamp == null && end_timestamp == null) {
      a = true;
    } else if (start_timestamp == null) {
      a = now <= end_datetime;
    } else if (end_timestamp == null) {
      a = now >= start_datetime;
    } else {
      a = now >= start_datetime && now <= end_datetime;
    }
    return a;
  }

  private definePerimeterStyle(name: string, valid: boolean) {
    if (valid) {
      return this.definePerimeterFreeStyle(name);
    } else {
      return this.definePerimeterInvalidStyle(name);
    }
  }

  private definePerimeterFreeStyle(name: string) {
    return new Style({
      fill: new Fill({
        color: 'rgba(60, 250, 0, 0.30)',
      }),
      stroke: new Stroke({
        color: '#007dff',
        width: 2,
      }),
      text: new Text({
        textAlign: 'left',
        textBaseline: 'top',
        font: '12px sans-serif',
        text: name,
        stroke: new Stroke({ color: 'gray', width: 0.5 }),
        offsetX: 0,
        offsetY: 0,
        //placement: new Text,
      }),
    });
  }

  private definePerimeterSelectStyle(name: string) {
    return new Style({
      fill: new Fill({
        color: 'rgba(155, 25, 0, 0.3)',
      }),
      stroke: new Stroke({
        color: '#ff0000',
        width: 2,
      }),
      text: new Text({
        textAlign: 'left',
        textBaseline: 'top',
        font: '12px sans-serif',
        text: name == '<selected>' ? '' : name,
        stroke: new Stroke({ color: '#ff0000', width: 0.5 }),
        offsetX: 0,
        offsetY: 0,
        //placement: new Text,
      }),
    });
  }

  public definePerimeterUpdateStyle(name: string) {
    return new Style({
      fill: new Fill({
        color: 'rgba(255, 25, 0, 0.3)',
      }),
      stroke: new Stroke({
        color: '#ff0000',
        width: 2,
      }),
      text: new Text({
        textAlign: 'left',
        textBaseline: 'top',
        font: '12px sans-serif',
        text: name == '<selected>' ? '' : name,
        stroke: new Stroke({ color: '#ff0000', width: 0.5 }),
        offsetX: 0,
        offsetY: 0,
        //placement: new Text,
      }),
    });
  }

  private definePerimeterInvalidStyle(name: string) {
    return new Style({
      fill: new Fill({
        color: 'rgba(60, 250, 0, 0.30)',
      }),
      stroke: new Stroke({
        color: '#007dff',
        width: 2,
      }),
      text: new Text({
        textAlign: 'left',
        textBaseline: 'top',
        font: '12px sans-serif',
        text: name,
        stroke: new Stroke({ color: 'gray', width: 0.5 }),
        offsetX: 0,
        offsetY: 0,
        //placement: new Text,
      }),
    });
  }

  private storeCoordinates(geometry: any) {
    if (geometry !== undefined) {
      let coords: Array<Array<string>> = [];
      let fc = JSON.parse(JSON.stringify(geometry)).flatCoordinates;
      for (let i = 0; i < fc.length; i += 2) {
        coords.push([fc[i].toString(), fc[i + 1].toString()]);
      }
      this.drawnPerimeterCoords = coords;
    } else {
      this.drawnPerimeterCoords = [];
    }
  }

  /* ************************************** */

  resetPopup() {
    this.popup = new Overlay({
      element: this.tooltip,
    });
  }

  enableMapEdit() {
    this.map.addInteraction(this.modify);
    this.map.addInteraction(this.draw);
    this.map.addInteraction(this.snap);
    this.flagEdit = true;
  }

  setEvents() {
    const selectedFeatures = this.select.getFeatures();

    this.select.on('change:active', function () {
      selectedFeatures.forEach(function (each) {
        selectedFeatures.remove(each);
      });
    });
  }

  enableMapUpdate() {
    this.select = new Select();
    this.map.addInteraction(this.select);
    let features = this.select.getFeatures();
    this.modify = new Modify({
      features: features,
    });
    this.modify.on('modifyend', (evt) => {
      console.log('modified -end');
      let geometry = evt.features.getArray()[0].getGeometry();
      this.storeCoordinates(geometry);
    });
    this.map.addInteraction(this.modify);
    //this.setEvents();
    this.map.addInteraction(this.snap);
    this.flagEdit = true;
  }

  disableMapEdit() {
    this.map.removeInteraction(this.snap);
    this.map.removeInteraction(this.modify);
    this.map.removeInteraction(this.draw);
    this.flagEdit = false;
  }

  disableMapInsert() {
    this.map.removeInteraction(this.draw);
  }

  getDrawnPerimeterCoordinates() {
    return this.drawnPerimeterCoords;
  }

  setMouseTarget(target: string) {
    this.mousePositionControl.setTarget(target);
    this.map.addControl(this.mousePositionControl);
  }

  setTooltip(target: HTMLElement | null) {
    if (target != null) this.tooltip = target;
    this.popup = new Overlay({
      element: this.tooltip,
    });
  }

  returnMap() {
    return this.map;
  }

  setMap(updatedMap: Map) {
    this.map = updatedMap;
  }

  setZoomLevel(zoomLevel: number) {
    this.map.getView().setZoom(zoomLevel);
  }

  setCenter(center: any) {
    this.map.getView().setCenter(center);
  }

  selectPerimeter(pr_name: string) {
    // Implement a feature selection function
    this.map.getLayers().forEach((layer) => {
      if (layer instanceof VectorLayer) {
        // Check if it's a vector layer
        const source = layer.getSource();
        source
          .getFeatures()
          .forEach(
            (feature: {
              get: (arg0: string) => string;
              setStyle: (arg0: Style) => void;
            }) => {
              if (feature.get('name') === pr_name) {
                // Apply a selected style to the feature
                //feature.setStyle(this.definePerimeterSelectStyle(pr_name));
              } else {
                // Reset the style for other features
                feature.setStyle(
                  this.definePerimeterStyle(
                    feature.get('name'),
                    this.checkPerimeterTimeValidity(
                      feature.get('start_timestamp'),
                      feature.get('end_timestamp')
                    )
                  )
                );
              }
            }
          );
      }
    });
  }

  unselectAllPerimeters() {
    this.map.getLayers().forEach((layer) => {
      if (layer instanceof VectorLayer) {
        // Check if it's a vector layer
        const source = layer.getSource();
        source
          .getFeatures()
          .forEach(
            (feature: {
              get: (arg0: string) => string;
              setStyle: (arg0: Style) => void;
            }) => {
              // Apply unselected (default style to the feature
              feature.setStyle(
                this.definePerimeterStyle(
                  feature.get('name'),
                  this.checkPerimeterTimeValidity(
                    feature.get('start_timestamp'),
                    feature.get('end_timestamp')
                  )
                )
              );
            }
          );
      }
    });
  }

  changeInteraction = (name: string) => {
    // select interaction working on "singleclick"
    let select = null;
    if (name == '<selected>')
      select = new Select({ style: this.definePerimeterSelectStyle(name) });
    if (name == '<add_new>')
      select = new Select({ style: this.definePerimeterUpdateStyle(name) });
    if (select !== null) {
      this.map.removeInteraction(select);
    }
    if (select !== null) {
      this.map.addInteraction(select);
    }
  };

  computeZoomLevel(d: number) {
    if (d < 200) {
      return 18;
    } else if (d < 500) {
      return 17;
    } else if (d < 1000) {
      return 16;
    } else if (d < 5000) {
      return 15;
    } else if (d < 10000) {
      return 14;
    } else if (d < 15000) {
      return 13;
    } else if (d < 20000) {
      return 12;
    } else {
      return 11;
    }
  }

  // rescale and center th4e view, based on the perimeter positions of all perimeters
  rescaleView(perimeters: Perimeter[]) {
    let x_m: number = 0.0;
    let y_m: number = 0.0;
    let c_x_min: number, c_x_max: number;
    let c_y_min: number, c_y_max: number;
    c_x_max = c_y_max = -1e10;
    c_x_min = c_y_min = 1e10;
    let n: number = 0;
    perimeters.forEach((p) => {
      let coords: [] = p.coordinates;
      coords.forEach((c) => {
        x_m += parseFloat(c[0]);
        y_m += parseFloat(c[1]);
        n++;
        // transform in meter and compute distance
        let c_m = transform(
          [parseFloat(c[0]), parseFloat(c[1])],
          'EPSG:4326',
          'EPSG:3857'
        );
        if (c_m[0] > c_x_max) c_x_max = c_m[0];
        if (c_m[1] > c_y_max) c_y_max = c_m[1];
        if (c_m[0] < c_x_min) c_x_min = c_m[0];
        if (c_m[1] < c_y_min) c_y_min = c_m[1];
      });
    });

    let max_distance: number = Math.sqrt(
      (c_x_max - c_x_min) ** 2 + (c_y_max - c_y_min) ** 2
    );

    this.setCenter([x_m / n, y_m / n]);
    this.setZoomLevel(this.computeZoomLevel(max_distance));
  }

  // rescale and center the view, based on the perimeter position
  rescaleViewPerimeter(coords: []) {
    let x_m: number = 0.0;
    let y_m: number = 0.0;
    let c_x_min: number, c_x_max: number;
    let c_y_min: number, c_y_max: number;
    c_x_max = c_y_max = -1e10;
    c_x_min = c_y_min = 1e10;
    let n: number = 0;
    coords.forEach((c) => {
      x_m += parseFloat(c[0]);
      y_m += parseFloat(c[1]);
      n++;
      // transform in meter and compute distance
      let c_m = transform(
        [parseFloat(c[0]), parseFloat(c[1])],
        'EPSG:4326',
        'EPSG:3857'
      );
      if (c_m[0] > c_x_max) c_x_max = c_m[0];
      if (c_m[1] > c_y_max) c_y_max = c_m[1];
      if (c_m[0] < c_x_min) c_x_min = c_m[0];
      if (c_m[1] < c_y_min) c_y_min = c_m[1];
    });

    let max_distance: number = Math.sqrt(
      (c_x_max - c_x_min) ** 2 + (c_y_max - c_y_min) ** 2
    );

    this.setCenter([x_m / n, y_m / n]);
    this.setZoomLevel(this.computeZoomLevel(max_distance));
  }

  drawPerimeterOnMap(coordinates: any, p: Perimeter) {
    const polygonFeature = new Feature(new Polygon(coordinates));
    polygonFeature.setProperties({
      id: p.id,
      name: p.name,
      selected: p.selected,
      center: p.center,
      coordinates: p.coordinates,
      start_timestamp: p.start_timestamp,
      end_timestamp: p.end_timestamp,
      init_zoom: p.init_zoom,
      msg_inside: p.msg_inside,
      msg_outside: p.msg_outside,
      scheduling: p.scheduling,
      user_ids: p.user_ids,
      users_count: p.users_count,
    });

    // new Polygon(coordinates).transform('EPSG:4326','EPSG:3857'));
    let source = new VectorSource({
      features: [polygonFeature],
    });

    var layer = new VectorLayer({
      source: source,
      style: this.definePerimeterStyle(
        p.name,
        this.checkPerimeterTimeValidity(p.start_timestamp, p.end_timestamp)
      ),
    });

    layer.set('id', p.id);

    this.map.addLayer(layer);
  }

  goToMarker(latlng: any) {
    this.setCenter([latlng[1], latlng[0]]);
    this.setZoomLevel(18);
  }

  // Add user marker
  addMarker(last_position: any, user_full_name: string): void {
    let latlng: any = last_position.value;
    this.goToMarker(latlng);

    const markerFeature = new Feature({
      geometry: new Point([latlng[1], latlng[0]]),
      text: 'ciao',
    });

    markerFeature.setStyle(
      new Style({
        image: new Icon({
          src: '../assets/img/person-icon-blue.png', // URL to marker icon
          scale: 0.5, // Adjust size as needed
        }),
        text: new Text({
          textAlign: 'left',
          textBaseline: 'top',
          font: '16px sans-serif',
          text: user_full_name,
          stroke: new Stroke({ color: 'gray', width: 0.4 }),
          offsetX: 20,
          offsetY: 0,
        }),
      })
    );

    const vectorSource = new VectorSource({
      features: [markerFeature],
    });

    const vectorLayer = new VectorLayer({
      source: vectorSource,
    });

    vectorLayer.set('id', user_full_name); //use text as layer id for the marker

    this.removeLayerFromMap(user_full_name);

    this.map.addLayer(vectorLayer);
  }

  resetMap() {
    this.resetPopup();
    this.unselectAllPerimeters();
    //this.removeAllPerimetersFromMap();
    this.setCenter(this.initialCenter);
    this.setZoomLevel(this.initialZoom);
  }

  /*   removePerimetersOfUserFromMap(user_id: number) {
    let layers = this.map.getAllLayers();
    for (let i = layers.length - 1; i >= 0; i--) {
      //console.log(layers[i].get('user_id'));
      if (
        layers[i].get('user_id') != undefined &&
        layers[i].get('user_id') === user_id
      )
        this.map.removeLayer(layers[i]);
    }
  } */

  removeAllPerimetersFromMap() {
    let layers = this.map.getAllLayers();

    for (let i = layers.length - 1; i >= 1; i--) {
      this.map.removeLayer(layers[i]);
    }
  }

  removeNewPerimeterFromMap() {
    if (this.newPerimeterFeature)
      this.vector.getSource()?.removeFeature(this.newPerimeterFeature);
  }

  removeLayerFromMap(id: any) {
    let layers = this.map.getAllLayers();
    for (let i = layers.length - 1; i >= 0; i--) {
      if (layers[i].get('id') != undefined && layers[i].get('id') === id) {
        this.map.removeLayer(layers[i]);
        // console.log ('layer. ' + layers[i].get('id').toString() + ' removed')
      }
    }
  }
}
