import { HttpClient, HttpParams } from '@angular/common/http';
import { delay, Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';

export interface Perimeter {
  id: number;
  name: string;
  selected: boolean;
  center: {
    name: string;
    coordinates: any;
  };
  coordinates: any;
  msg_inside: string;
  msg_outside: string;
  scheduling: any;
  init_zoom: number;
  start_timestamp: any;
  end_timestamp: any;
  user_ids: any;
  users_count: number;
}

export interface User {
  id: number;
  prefix_name: string;
  given_name: string;
  family_name: string;
  full_name: string;
  username: string;
  gender: string;
  birth_date: string;
  address: any;
  marital_status: string;
  language: string;
  email: string;
  height: number;
  education: string;
  religious_affiliation: string;
  selected: boolean;
  last_position: any;
}

export interface UserPerimeter {
  id: number;
  user_id: number;
  perimeter_id: number;
  scheduling: any;
  start_timestamp: any;
  end_timestamp: any;
}

// State machine's status
export enum Status {
  START,
  PERIMETER_SELECTED,
  ADDNEW,
  SAVENEW,
  EDITING,
  SAVEEDIT,
  DELETING,
}

// Team/User mode
export type UserId = number | null;

@Injectable({
  providedIn: 'root',
})
export class GeofencingService {
  api_url: string = '';
  pilot: string = '';
  user_id: UserId = null;

  // api_url = "http://localhost:5016";              // API serving the core postgres db
  //api_url ="https://alss.eclexys.com:15016";

  auth_token: string;

  constructor(private httpClient: HttpClient) {
    this.auth_token = '';
  }

  async setToken(token: string) {   
    this.auth_token = token;    
  }

  setApiUrl(api_url: string, pilot: string) {
    this.api_url = api_url;
    this.pilot = pilot;
  }

  setUser(user_id: UserId) {
    this.user_id = user_id;
  }

  getUser(): UserId {
    return this.user_id;
  }

  getPilot() {
    return this.pilot;
  }

  /**
   * Returns all users
   * done
   */
  getAllUsers(filter = '', sortOrder = 'asc'): Observable<User[]> {
    return this.httpClient.get<User[]>(this.api_url + '/end_users', {
      params: new HttpParams()
        .set('filter', filter)
        .set('sortOrder', sortOrder),
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + this.auth_token,
      },
    });
  }

  /**
   * Returns a user by user_id
   * done
   */
  getUserById(user_id: number): Observable<User> {
    return this.httpClient.get<User>(
      this.api_url + '/end_users/' + user_id.toString(),
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + this.auth_token,
        },
      }
    );
  }

  /**
   * Returns all perimeters
   * done
   */
  getAllPerimeters(user_id: number | null): Observable<Perimeter[]> {
    let queryParams = new HttpParams();
    if (user_id != null) queryParams = queryParams.append('user_id', user_id);
    return this.httpClient.get<Perimeter[]>(
      this.api_url + '/geofencing/perimeters',
      {
        params: queryParams,
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + this.auth_token,
        },
      }
    );
  }

  /** done
   * This method save or update a perimeter
   */
  insertNewPerimeter(perimeter: Perimeter): Observable<Perimeter> {
    const headers = {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + this.auth_token,
    };
    return this.httpClient.post<Perimeter>(
      this.api_url + '/geofencing/perimeters',
      perimeter,
      {
        headers,
      }
    );
  }

  /** done
   * This method save or update a perimeter
   */
  updatePerimeter(perimeter: Perimeter, id: number): Observable<Perimeter> {
    const headers = {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + this.auth_token,
    };
    return this.httpClient.put<Perimeter>(
      this.api_url + '/geofencing/perimeters/' + id.toString(),
      perimeter,
      { headers }
    );
  }

  /**
   * This method update the scheduling for a perimeter
   * done
   */
  updateScheduling(perimeter_id: number, scheduling: any): Observable<any> {
    const headers = {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + this.auth_token,
    };
    return this.httpClient.put<any>(
      this.api_url + '/geofencing/perimeters/' + perimeter_id.toString(),
      { scheduling: scheduling },
      { headers }
    );
  }

  /**
   * This method update the users for a perimeter
   * done
   */
  updateUsersPerimeter(
    perimeter_id: number,
    user_ids: number[]
  ): Observable<any> {
    const headers = {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + this.auth_token,
    };
    return this.httpClient.put<any>(
      this.api_url + '/geofencing/perimeters/' + perimeter_id.toString(),
      { user_ids: user_ids },
      { headers }
    );
  }

  /**
   * This method delete a perimeter
   * done
   */
  deletePerimeter(perimeter_id: number): Observable<Perimeter> {
    const headers = {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + this.auth_token,
    };
    return this.httpClient.delete<Perimeter>(
      this.api_url + '/geofencing/perimeters/' + perimeter_id.toString(),
      { headers }
    );
  }

  /**
   * This method delete all perimeters having name  
  deletePerimetersByName(name: string): Observable<any> {
    const headers = {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + this.auth_token
    };
    this.httpClient
      .post<any>(
        this.api_url + '/mui/remove_perimeters_by_name',
        { name: name },
        { headers }
      )      
  }
  */

  /**
   * Returns last geoposition of all users
   * done
   */

  getLastGeoPositions(user_id: number | null): Observable<any> {
    let queryParams = new HttpParams();
    if (user_id != null) queryParams = queryParams.append('user_id', user_id);
    return this.httpClient.get<any>(this.api_url + '/geofencing/outdoors', {
      params: queryParams,
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + this.auth_token,
      },
    });
  }

  getAllRemoteTexts(): Observable<any[]> {
    return this.httpClient.get<any[]>(this.api_url + '/texts', {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + this.auth_token,
      },
    });
  }
}
